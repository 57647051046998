import React from "react";
import Footer from "../../components/App/Footer";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import heroImage from "../../assets/images/financing-solutions/trade-finance.jpg";
import QuoteCard from "../../components/Common/QuoteCard";
import CardInfo from "../../components/Common/CardInfo";
import platformDoc from "../../documents/SEA-Easy-Capital-Platform.pdf";
import discounting from "../../assets/images/financing-solutions/reverse-factoring.svg";
import platform from "../../assets/images/financing-solutions/platform.svg";
import whatisDoc from "../../documents/what-is-document.pdf";

const TradeFinance = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <PageBanner
          pageTitle="Trade Finance"
          pageMainTitle="Competitive Trade Finance terms for your suppliers"
          pageDescription="If you are buying your goods in the region or globally, we can help you remain competitive. 
          By providing competitive trade finance terms to your suppliers, we, at SEA Easy Capital can assist in maintaining and growing your place within the market."
          buttonText="Learn More"
          image={heroImage}
          scrollDestination="#"
        />

        {/* discounting and how platform works section  */}
        <section className="bg-blue ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <CardInfo
                  image={discounting}
                  title="What is Trade Finance?"
                  buttonText=" Learn More"
                  linkDestination={whatisDoc}
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <CardInfo
                  image={platform}
                  title="How our Platform works"
                  buttonText="Learn More"
                  linkDestination={platformDoc}
                />
              </div>
            </div>
          </div>
        </section>

        {/* quote section  */}
        {/* <section className="quote-section">
          <div className="container">
            <QuoteCard
              quote="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
              personname="John Doe"
              company="ABC Corp."
            />
          </div>
        </section> */}
      </div>
      <Footer />
    </Layout>
  );
};

export default TradeFinance;
